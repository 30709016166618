<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>

        <b-row>
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  @click="openLocations()"
                  
                >
                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Locations</span>
                </b-badge>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Hand Sanitizers Checklist</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="3">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="site" @change="siteChange">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Shift"
                  class="required"

                >
                  <b-form-select v-model="shift" @change="getLocationData()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3" >

            <b-form-group
              label="Date"
              class="required"

            > 
              <b-form-datepicker
              
                v-model="date"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
                @input="getLocationData()"
              />

                
            </b-form-group>

          </b-col>

          <b-col md="3" >
            <b-form-group
              label="Checked By"
              class="required"

            >
              <b-form-input 
                autocomplete="off"
                placeholder="3 to 50 characters"
                v-model="checked_by"
              />
                
            </b-form-group>
          </b-col>

          

        </b-row>
    
    </b-card>
    
    <b-form @submit="formSubmit">
    

    <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
      <div v-if="site && shift != ''">
        <b-table 
              responsive
              show-empty
              ref = "refUserListTable"
              class = "position-relative bigTableFH"
              primary-key = "id"
              empty-text = "No records found"
              :fields = "tableColumns"
              :items = "list.data"
          >

          <template slot="thead-top" slot-scope="data">
            <tr>
              
              <th colspan="2"> 
                <b-form-select v-model="list.building" @change="buildingChange(list.building,i)">

                  <b-form-select-option value="" disabled>Select Building</b-form-select-option>
                  
                  <b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id">{{building.name | capitalize}}</b-form-select-option>
                  
                  
                </b-form-select>
              </th>
              <th colspan="3"></th>
              <th colspan="4" style="background-color: #b8daff;color:black;text-align:center;">Liquid Balance in the pack</th>
              <th colspan="2" style="background-color: #f5c6cb; color:black;text-align:center;">Condition</th>
              <th colspan="4">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeData(i)"
                >
                 Remove
                </b-button>
              </th>
            </tr>
          </template>


          <template #cell(level)="items">

              
              <p class="wordBreak">{{items.item.level}}</p>

              <!-- <b-form-input autocomplete="off" placeholder="" v-model="items.item.level" /> -->
         
          </template>

          <template #cell(location)="items">

            <!-- <b-form-input autocomplete="off" placeholder="" v-model="items.item.location" /> -->
            <p class="wordBreak">{{items.item.location}}</p>
         
          </template>
          <template #cell(battery_weak)="items">
           
            <b-form-checkbox
              v-model="items.item.battery_weak"
              value="yes"
              unchecked-value="no"
              class="custom-control-danger"
            />
         
          </template>
          <template #cell(machine_fault)="items">

            <b-form-checkbox
              v-model="items.item.machine_fault"
              value="yes"
              unchecked-value="no"
              class="custom-control-warning"
            />
         
          </template>

          <template #cell(liquid)="items">

            <b-form-checkbox
              v-model="items.item.liquid"
              value="yes"
              unchecked-value="no"
              class="custom-control-info"
            />
         
          </template>

          <template #cell(one_fourth)="items">
           
            <b-form-checkbox
              v-model="items.item.one_fourth"
              value="yes"
              unchecked-value="no"
              class="custom-control-danger"
              @change="updateLiquidCheckbox($event,items.index,'one_fourth',i)"
            />
         
          </template>
          <template #cell(half)="items">

            <b-form-checkbox
              v-model="items.item.half"
              value="yes"
              unchecked-value="no"
              class="custom-control-warning"
              @change="updateLiquidCheckbox($event,items.index,'half',i)"
            />
         
          </template>
          <template #cell(three_fourth)="items">

            <b-form-checkbox
              v-model="items.item.three_fourth"
              value="yes"
              unchecked-value="no"
              class="custom-control-info"
              @change="updateLiquidCheckbox($event,items.index,'three_fourth',i)"
            />
         
          </template>
          <template #cell(full)="items">
           
            <b-form-checkbox
              v-model="items.item.full"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
              @change="updateLiquidCheckbox($event,items.index,'full',i)"
            />
         
          </template>



          <template #cell(ok)="items">
           
            <b-form-checkbox
              v-model="items.item.ok"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
              @change="updateConditionCheckbox($event,items.index,'ok',i)"
            />
              
         
          </template>
          <template #cell(not_ok)="items">
           

            <b-form-checkbox
              v-model="items.item.not_ok"
              value="yes"
              unchecked-value="no"
              class="custom-control-danger"
              @change="updateConditionCheckbox($event,items.index,'not_ok',i)"
            />
         
          </template>
          
          
          <template #cell(supervisor_signature)="items">

            <SupervisorSignature v-bind:tindex="i" v-bind:index="items.index" v-bind:signature="items.item.supervisor_signature"/>
          
          </template>
          <template #cell(remarks)="items">
           
            <b-form-input autocomplete="off" placeholder="" v-model="items.item.remarks" />
          </template>

          <template #cell(actions)="items">

            <!-- <b-button
              size="sm"
              variant="danger"
              class="mr-1"
              @click="removeData(items.index)"
            >
             X
            </b-button> -->
          </template>

          

        </b-table>
      </div>
    </b-card>

    <b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="goBack()"
          >
            Cancel
          </b-button>

          

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            type="submit"
          >
            Submit
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mr-1 float-right"
            @click="addMore"
            v-if="site != ''"
          >
            Add More
          </b-button>

        </b-col>
      </b-row>

    </b-card>

    </b-form>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import SupervisorSignature from './SupervisorSignature.vue';
import Bus from "../../../event-bus";

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,SupervisorSignature,BBreadcrumb
  },
  
  data() {
    return {
      //locations:[],
      
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      
      //site:this.$route.params.site_id,
      site:'',
      checked_by:this.$store.getters.currentUser.full_name,
      shift:'',
      building:'',
      buildings:[],
      sites:[],
      shifts:[],

      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      checklistData:[],
      
      openedSignModel:null,

      tableColumns:[
        { key: 'level', label: 'Level', sortable: false , thStyle:  {width: '10%'}},
        { key: 'location', label: 'Location', sortable: false , thStyle:  {width: '12%'}},
        { key: 'battery_weak', label: 'Battery Weak?', sortable: false , thStyle:  {width: '8%'}},
        { key: 'machine_fault', label: 'Machine Faulty?', sortable: false , thStyle:  {width: '8%'}},
        { key: 'liquid', label: 'Sensor Faulty?', sortable: false , thStyle:  {width: '7%'}},

        { key: 'one_fourth', label: '1/4', sortable: false , thStyle:  {width: '5%'}},
        { key: 'half', label: '1/2', sortable: false , thStyle:  {width: '5%'}},
        { key: 'three_fourth', label: '3/4', sortable: false , thStyle:  {width: '5%'}},
        { key: 'full', label: 'Full', sortable: false , thStyle:  {width: '5%'}},
        
        { key: 'ok', label: 'Ok', sortable: false , thStyle:  {width: '5%'}},
        { key: 'not_ok', label: 'Not Ok', sortable: false , thStyle:  {width: '5%'}},
        
        { key: 'supervisor_signature', label: 'Supervisor Signature', sortable: false , thStyle:  {width: '9%'}},
        { key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '16%'}},
        
        /*{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},*/

      ],
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    goBack(){
      var msg = 'Do you want to Go back without saving your report?';
      this.decisionAlert(msg)
      .then(result => {
        if (result.value) {
          this.$router.go(-1);
        }
      })
    },
    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    actualForm(e){
      return this.$store.dispatch(POST_API, {
        data:{
            date : this.date,
            site :this.site,
            checked_by : this.checked_by,
            shift : this.shift,
            checklistData : this.checklistData,
            //building : this.building,
        },
        api : '/api/submit-hand-sanitizer-checklist'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              // this.showDismissibleAlert = true;
						// window.scrollTo(0,0);
						this.errorAlertCF()
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                //this.site = this.$route.params.site_id;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
            
                }else{

                    this.site = '';
                }
                this.siteChange();

                if (data != null) {
                    window.open(data, '_blank');
                }

                this.$router.go(-1);
                  
              });
          }
      });
    },

    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();						
      } else {		
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();						
          }
        })
      }			
    },

    getLocationData(){
      this.shift != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
             date:this.date,
             shift:this.shift != '' ? this.shift : null,
           },
           api: '/api/hand-sanitizer-locations-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.checklistData = data;
                return this.checklistData;
            }
        });
    },
    getShiftData(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
   
    siteChange(){
      
      this.checklistData = [];
      this.shifts = [];
      this.shift = '';

      if (this.site != '') {
        this.getShiftData();
        this.getBuildings();
        this.getLocationData();
      }
    },

    buildingChange(id,i){
      
      if (id != '') {

        return this.$store.dispatch(POST_API, {
           data:{
             building:id,
             site:this.site
           },
           api: '/api/building-location-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                this.checklistData[i].data = data;
                return this.checklistData;
            }
        });


      }else{
        this.checklistData[i].data = [];
      }

    },
    addMore(){
      
      var order = 0;

      if (this.checklistData.length > 0) {
        var lastIndex = this.checklistData.length - 1;
        order = this.checklistData[lastIndex].order + 1;
      }

      var obj = {
          building:'',
          data : [],
          order:order,
      };

      this.checklistData.push(obj);
    },
    removeData(index){
      this.checklistData.splice(index,1);
      if (this.checklistData.length == 0) {
        this.addMore();
      }
    },
    updateConditionCheckbox(e,i,type,index){
      
      if (e == 'no') {
        this.checklistData[index].data[i].ok      = 'no';
        this.checklistData[index].data[i].not_ok  = 'no';
      }else{
        if (e == 'yes' && type == 'ok') {

          this.checklistData[index].data[i].not_ok  = 'no';
          

        }else if(e == 'yes' && type == 'not_ok'){

          this.checklistData[index].data[i].ok      = 'no';
          

        }
      }
    },
    updateLiquidCheckbox(e,i,type,index){
      
      if (e == 'no') {
        this.checklistData[index].data[i].one_fourth    = 'no';
        this.checklistData[index].data[i].half          = 'no';
        this.checklistData[index].data[i].three_fourth  = 'no';
        this.checklistData[index].data[i].full          = 'no';
      }else{

        if (e == 'yes' && type == 'one_fourth') {

          this.checklistData[index].data[i].half          = 'no';
          this.checklistData[index].data[i].three_fourth  = 'no';
          this.checklistData[index].data[i].full          = 'no';

        }else if(e == 'yes' && type == 'half'){

          this.checklistData[index].data[i].one_fourth      = 'no';
          this.checklistData[index].data[i].three_fourth    = 'no';
          this.checklistData[index].data[i].full            = 'no';

        }else if(e == 'yes' && type == 'three_fourth'){

          this.checklistData[index].data[i].one_fourth  = 'no';
          this.checklistData[index].data[i].half        = 'no';
          this.checklistData[index].data[i].full        = 'no';

        }else if(e == 'yes' && type == 'full'){

          this.checklistData[index].data[i].one_fourth  = 'no';
          this.checklistData[index].data[i].half        = 'no';
          this.checklistData[index].data[i].three_fourth= 'no';
          
        }
      }
    },
    
    getBuildings(){
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                site:this.site,
                form:'Hand Sanitizers Checklist'
            },
            api: '/api/all-buildings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.buildings = this.$store.getters.getResults.data;
                return this.buildings;
            }
        });
    },
    openLocations(){
      this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-site' ,id:'all-building',form:'Hand_Sanitizers_Checklist'}})
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Hand Sanitizers Checklist',   
        active:true             
      }];
      return item;
    }
    
  },
  mounted(){
    this.allSites();
    this.siteChange();
    //this.getLocationData();
    // to add crew sign
    Bus.$on('update-image', (data) => {
        this.checklistData[data.tindex].data[data.index].supervisor_signature = data.data;
    })

    // to delete crew sign
    Bus.$on('remove-image', (data,index) => {
        this.checklistData[index].data[data].supervisor_signature = {
            default:'no',
            name:'',
            image:null,
        };
    })
  }
  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
